<template>
    <div class="container-market">
        <div class="page-market">
            <h1>Общие положения</h1>
            <ol>
                <li>
                    Валюта <a href="/market/app">LEADS.MARKET</a> — LEADS.COINS.
                </li>
                <li>
                    Только LEADS.COINS можно обменять на товары <a href="/market/app">LEADS.MARKET</a>.
                </li>
                <li>
                    LEADS.COINS предназначены только для использования в <a href="/market/app">LEADS.MARKET</a>. Их нельзя
                    обменять на деньги и вывести из кабинета.
                </li>
            </ol>

            <h2>Правила начисления</h2>
            <ol>
                <li>
                    LEADS.COINS начисляются за каждую вышедшую из HOLDa одобренную конверсию по офферам.
                </li>
                <li>
                    Количество начисляемых LEADS.COINS зависит от индивидуальных условий по офферам и целей по одобренным конверсиям, поэтому может отличаться у разных вебмастеров.
                </li>
                <li>LEADS.COINS начисляются только по офферам, в карточках которых указана соответствующая информация. За конверсии по другим офферам LEADS.COINS не начисляются.</li>
                <li>Историю своих начислений вы можете посмотреть <a href="/financeAccounting/pointsHistory">здесь</a>.</li>
                <li>При наличии у вебмастера нескольких аккаунтов, LEADS.COINS суммируются на основном аккаунте.</li>
                <li>
                    Если вы хотите привязать дополнительные аккаунты или отвязать текущие, обратитесь, пожалуйста, к своему
                    аккаунт-менеджеру.
                </li>
                <li>
                    В акции участвуют только те офферы, в карточке которых указаны акционные баллы. За конверсии по другим
                    офферам LEADS.COINS не начисляются.
                </li>
                <li>Баланс заработанных LEADS.COINS обновляется сразу после выхода одобренной конверсии из HOLDа.</li>
                <li>Баланс заработанных LEADS.COINS обновляется сразу после выхода конверсии из холда.</li>
                <li>Все начисленные LEADS.COINS действительны до 31 декабря календарного года. Весь объем LEADS.COINS, находящийся на балансе вебмастеров на 23:59:59 31 декабря, перечисляется в пользу “CentrMama” или другие благотворительные проекты, поддерживаемые партнерской сетью LEADS.SU. Период действия LEADS.COINS, а также дата их перечисления на благотворительные проекты определяются и могут быть изменены LEADS.SU. Следите за новостями в ТГ - @leadssulive</li>
            </ol>

            <h2>Порядок покупки</h2>

            <ol>
                <li>
                    При обмене LEADS.COINS на товары в <a href="/market/app">LEADS.MARKET</a> соответствующая сумма списывается
                    со счета вебмастера
                </li>
                <li>
                    Вебмастер может обменять LEADS.COINS на призы из <a href="/market/app">LEADS.MARKET</a> в любое время при
                    накоплении достаточной суммы.
                </li>
                <li>Доставка призов в регионы РФ осуществляется за счет партнерской сети LEADS.SU.</li>
                <li>Доставка в другие страны осуществляется за счет вебмастера.</li>
                <li>В отдельные регионы доставка может быть невозможной. Подробнее уточняйте у своего аккаунт-менеджера.</li>
                <li>
                    За статусом доставки заказанных товаров вы можете следить на странице
                    <a href="/market/app#/orders">«Мои заказы»</a>
                </li>
            </ol>

            <h2>Иные положения</h2>
            <ol>
                <li>
                    Условия работы могут быть изменены партнерской сетью LEADS, следите за обновлениями, чтобы оставаться в
                    курсе событий.
                </li>
                <li>
                    Список призов, представленный на витрине <a href="/market/app">LEADS.MARKET</a>,
                    может быть изменен в любое время
                </li>
                <li>
                    Если какого-то товара или приза нет в наличии, то партнерская программа LEADS.SU вправе отказать в его
                    доставке, сообщив об этом, и вернув в полном объеме акционные баллы LEADS.COINS, если те были потрачены
                </li>
                <li>Стоимость призов может меняться в зависимости от текущего курса USD.</li>
                <li>При отсутствии выбранной модели товара будет произведена замена товара на аналогичный.</li>
            </ol>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Rules',
};
</script>
